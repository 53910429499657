

























































































































































































































































































































































































































































import Vue from "vue";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { createNamespacedHelpers } from "vuex";
import { accountingAccountService } from "@service/accounting-account.service";
import { ResponseListAccountingAccount } from "@interface/accounting-account.interface";

const { mapMutations, mapState } = createNamespacedHelpers("productStoreV2");

export default Vue.extend({
  name: "ProductSettingAccount",
  data() {
    return {
      formProps: {
        costOfSalesAccountId: {
          label: "lbl_cost_of_sales_account",
          name: "costOfSalesAccountId",
          placeholder: "lbl_type_here",
        },
        inventoryAccountId: {
          label: "lbl_inventory_account",
          name: "inventoryAccountId",
          placeholder: "lbl_type_here",
        },
        jobCostingAccountId: {
          label: "lbl_job_costing_account",
          name: "jobCostingAccountId",
          placeholder: "lbl_type_here",
        },
        expensePurchaseAccountId: {
          label: "lbl_expense_purchase_account",
          name: "expensePurchaseAccountId",
          placeholder: "lbl_type_here",
        },
        purchaseReturnAccountId: {
          label: "lbl_purchase_return_account",
          name: "purchaseReturnAccountId",
          placeholder: "lbl_type_here",
        },
        purchaseDiscountAccountId: {
          label: "lbl_purchase_discount_account",
          name: "purchaseDiscountAccountId",
          placeholder: "lbl_type_here",
        },
        assetCostAccountId: {
          label: "lbl_asset_cost_account",
          name: "assetCostAccountId",
          placeholder: "lbl_type_here",
        },
        assetClearingAccountId: {
          label: "lbl_asset_clearing_account",
          name: "assetClearingAccountId",
          placeholder: "lbl_type_here",
        },
        assetDepreciationAccountId: {
          label: "lbl_asset_depreciation_account",
          name: "assetDepreciationAccountId",
          placeholder: "lbl_type_here",
        },
        assetAccumulationAccountId: {
          label: "lbl_asset_accumulation_account",
          name: "assetAccumulationAccountId",
          placeholder: "lbl_type_here",
        },
        unbilledAccountId: {
          label: "lbl_unbilled_account",
          name: "unbilledAccountId",
          placeholder: "lbl_type_here",
        },
        salesAccountId: {
          label: "lbl_sales_account",
          name: "salesAccountId",
          placeholder: "lbl_type_here",
        },
        salesReturnAccountId: {
          label: "lbl_sales_return_account",
          name: "salesReturnAccountId",
          placeholder: "lbl_type_here",
        },
        salesDiscountAccountId: {
          label: "lbl_sales_discount_account",
          name: "salesDiscountAccountId",
          placeholder: "lbl_type_here",
        },
      },
      dtList: {
        costOfSalesAccount: {} as ResponseListAccountingAccount,
        inventoryAccount: {} as ResponseListAccountingAccount,
        jobCostingAccount: {} as ResponseListAccountingAccount,
        expensePurchaseAccount: {} as ResponseListAccountingAccount,
        purchaseReturnAccount: {} as ResponseListAccountingAccount,
        purchaseDiscountAccount: {} as ResponseListAccountingAccount,
        assetCostAccount: {} as ResponseListAccountingAccount,
        assetClearingAccount: {} as ResponseListAccountingAccount,
        assetDepreciationAccount: {} as ResponseListAccountingAccount,
        assetAccumulationAccount: {} as ResponseListAccountingAccount,
        unbilledAccount: {} as ResponseListAccountingAccount,
        salesAccount: {} as ResponseListAccountingAccount,
        salesReturnAccount: {} as ResponseListAccountingAccount,
        salesDiscountAccount: {} as ResponseListAccountingAccount,
      },
      form: {
        costOfSalesAccountId: undefined,
        expensePurchaseAccountId: undefined,
        inventoryAccountId: undefined,
        jobCostingAccountId: undefined,
        purchaseDiscountAccountId: undefined,
        purchaseReturnAccountId: undefined,
        salesAccountId: undefined,
        salesDiscountAccountId: undefined,
        salesReturnAccountId: undefined,
        unbilledAccountId: undefined,
        assetCostAccountId: undefined,
        assetClearingAccountId: undefined,
        assetDepreciationAccountId: undefined,
        assetAccumulationAccountId: undefined,
      },
      formRules: {
        costOfSalesAccountId: [
          { required: true, message: this.$t("lbl_validation_required_error"), trigger: "change"}
        ],
        expensePurchaseAccountId: [
          { required: true, message: this.$t("lbl_validation_required_error"), trigger: "change"}
        ],
        inventoryAccountId: [
          { required: true, message: this.$t("lbl_validation_required_error"), trigger: "change"}
        ],
        jobCostingAccountId: [
          { required: true, message: this.$t("lbl_validation_required_error"), trigger: "change"}
        ],
        purchaseDiscountAccountId: [
          { required: true, message: this.$t("lbl_validation_required_error"), trigger: "change"}
        ],
        purchaseReturnAccountId: [
          { required: true, message: this.$t("lbl_validation_required_error"), trigger: "change"}
        ],
        salesAccountId: [
          { required: true, message: this.$t("lbl_validation_required_error"), trigger: "change"}
        ],
        salesDiscountAccountId: [
          { required: true, message: this.$t("lbl_validation_required_error"), trigger: "change"}
        ],
        salesReturnAccountId: [
          { required: true, message: this.$t("lbl_validation_required_error"), trigger: "change"}
        ],
        unbilledAccountId: [
          { required: true, message: this.$t("lbl_validation_required_error"), trigger: "change"}
        ]
      }
    };
  },
  computed: {
    ...mapState({
      storeProduct: (state: any) => state.theProduct
    })
  },
  mounted() {
    this.fillAll();
  },
  activated() {
    this.preFill();
  },
  methods: {
    ...mapMutations([
      "SET_PRODUCT"
    ]),
    getListCOA(params: RequestQueryParamsModel): Promise<ResponseListAccountingAccount> {
      return accountingAccountService.getListCOA(params);
    },
    fillAll(): void {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      this.getListCOA(params)
      .then((res) => {
        this.dtList.costOfSalesAccount = res;
        this.dtList.inventoryAccount = res;
        this.dtList.jobCostingAccount = res;
        this.dtList.expensePurchaseAccount = res;
        this.dtList.purchaseReturnAccount = res;
        this.dtList.purchaseDiscountAccount = res;
        this.dtList.assetCostAccount = res;
        this.dtList.assetClearingAccount = res;
        this.dtList.assetDepreciationAccount = res;
        this.dtList.assetAccumulationAccount = res;
        this.dtList.unbilledAccount = res;
        this.dtList.salesAccount = res;
        this.dtList.salesReturnAccount = res;
        this.dtList.salesDiscountAccount = res;
      })
      .then(() => {
        this.preFill();
        this.handleItemLimit();
      });
    },
    preFill(): void {
      this.form = { ...this.form, ...this.storeProduct.productAccount };
    },
    onchangeSelect(): void {
      const dtSettingAcc = {
        productAccount: {...this.form}
      };
      this.SET_PRODUCT({
        ...this.storeProduct,
        ...dtSettingAcc
      });
    },
    handleItemLimit(): void {
      const obj = [
        "costOfSalesAccount",
        "inventoryAccount",
        "jobCostingAccount",
        "expensePurchaseAccount",
        "purchaseReturnAccount",
        "purchaseDiscountAccount",
        "assetCostAccount",
        "assetClearingAccount",
        "assetDepreciationAccount",
        "assetAccumulationAccount",
        "unbilledAccount",
        "salesAccount",
        "salesReturnAccount",
        "salesDiscountAccount"
      ];
      const obj2 = [
        "costOfSalesAccountId",
        "inventoryAccountId",
        "jobCostingAccountId",
        "expensePurchaseAccountId",
        "purchaseReturnAccountId",
        "purchaseDiscountAccountId",
        "assetCostAccountId",
        "assetClearingAccountId",
        "assetDepreciationAccountId",
        "assetAccumulationAccountId",
        "unbilledAccountId",
        "salesAccountId",
        "salesReturnAccountId",
        "salesDiscountAccountId",
      ];
      for (let i = 0; i < obj.length; i++) {
        const element = obj[i];
        const acc = this.dtList[element].data.find(x => x.id === this.form[obj2[i]]);
        if (!acc) {
          const [code, description] = this.storeProduct.productAccount[element + "Name"]?.split(" - ") || ["", ""];
          if (code && description) {
            this.dtList[element].data.push({id: this.form[obj2[i]], code, description });
          }
        }
      }
    },
    async handleSearch(search = "", dt = ""): Promise<void> {
      try {
        const params: RequestQueryParamsModel = {
          limit: 10,
          page: 0,
          search: `code~*${search}*_OR_description~*${search}*`
        };
        const res = await this.getListCOA(params);
        this.dtList[dt] = res;
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      }
    }
  },
});
